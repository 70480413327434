import { useState } from 'react';
import { connect } from 'react-redux';
import { subscribeUser } from '../../actions/warranty';
import logo from '../../img/logo.png';
import payments from '../../img/payment_methods.png';
import { FiPhoneCall } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';

const Footer = ({ warranty: { score, loadingSubscribe }, subscribeUser }) => {
  const [email, setEmail] = useState('');
  const [isError, setError] = useState(false);

  const submitEmail = (e) => {
    e.preventDefault();
    if (!email.includes('@') || !email.includes('.')) {
      setError(true);
      return;
    }

    subscribeUser(email);
    setError(false);
    setEmail('');
  };

  return (
    <footer>
      <div className='footer-container'>
        <div className='footer-body'>
          <section className='footer-info'>
            <article className='footer-info-article'>
              <a href='https://gritrgear.com/'>
                <img src={logo} alt='logo' className='header-logo' />
              </a>
              <p className='footer-contact-row'>
                <IoLocationOutline className='footer-contact-icon footer-location-icon' />
                <span>
                  7901 Boulevard 26 Ste 200
                  <br /> North Richland Hills, TX, 76180
                </span>
              </p>
              <p className='footer-contact-row'>
                <FiPhoneCall className='footer-contact-icon' />
                <a
                  href='tel:Toll-free: 866-515-0262 Local: 817-968-7159'
                  className='footer-call'
                >
                  Toll-free: 866-515-0262
                  <br /> Local: 817-968-7159
                </a>
              </p>
            </article>
            <article className='footer-info-article'>
              <h5 className='footer-article-header'>FOR CUSTOMERS</h5>
              <ul className='footer-article-list'>
                <li>
                  <a href='https://gritrgear.com/about-us/'>About us</a>
                </li>
                <li>
                  <a href='https://gritrgear.com/contact-us/'>Contact us</a>
                </li>
                <li>
                  <a href='https://gritrgear.com/customer-service/'>
                    Customer Service
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/privacy-and-security-policy/'>
                    Privacy and Security
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/ada-statement/'>
                    ADA Statement
                  </a>
                </li>
              </ul>
            </article>
            <article className='footer-info-article'>
              <h5 className='footer-article-header'>ORDER INFORMATION</h5>
              <ul className='footer-article-list'>
                <li>
                  <a href='https://gritrgear.com/offer-terms-conditions/'>
                    Offer Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/prices-sales-tax-content-policies/'>
                    Prices / Sales Tax / Content Policies
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/returns-refunds-policy-start-a-return/'>
                    Returns & Refunds Policy / Start a Return
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/order-acceptance/'>
                    Order Acceptance
                  </a>
                </li>
              </ul>
            </article>
            <article className='footer-info-article'>
              <h5 className='footer-article-header'>SHIPPING AND TAXES</h5>
              <ul className='footer-article-list'>
                <li>
                  <a href='https://gritrgear.com/shipping-returns/'>
                    Shipping & Delivery
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/domestic-restrictions/'>
                    Domestic restrictions
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/international-orders-and-shipping/'>
                    International ordering
                  </a>
                </li>
                <li>
                  <a href='https://gritrgear.com/export-policy/'>
                    Export policy
                  </a>
                </li>
              </ul>
            </article>
          </section>
          <div className='footer-devider'></div>
          <div className='footer-subscribe'>
            <div className='footer-subscribe-box'>
              <h5 className='footer-article-header'>
                Sign Up for our Newsletter
              </h5>
              <form className='footer-form'>
                <input
                  className={`footer-input ${
                    isError ? 'footer-input-error' : ''
                  }`}
                  type='text'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Join our Newsletter...'
                />
                <button
                  className='footer-form-btn'
                  onClick={submitEmail}
                  disabled={score < 0.5 || loadingSubscribe ? true : false}
                >
                  SUBMIT
                </button>
              </form>
              <p className='footer-alert'>
                {isError ? 'Please enter correct email' : ''}
              </p>
            </div>
            <div className='footer-subscribe-box'>
              <h5 className='footer-article-header'>Payment Method</h5>
              <img src={payments} alt='payment methods' />
            </div>
          </div>
        </div>
      </div>
      <div className='footer-body footer-copyright'>
        <span className='footer-copyright-text'>© 2021</span>
        <span>GritrGear</span>
        <span className='footer-copyright-devider'>|</span>
        <span>Sitemap</span>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  warranty: state.warranty,
});

export default connect(mapStateToProps, { subscribeUser })(Footer);
