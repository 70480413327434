import { connect } from 'react-redux';
import ModalInfo from '../modals/ModalInfo';
import ModalConfirmation from '../modals/ModalConfirmation';

const Modal = ({ modal: { type } }) => {
  switch (type) {
    case 'info':
      return <ModalInfo />;
    case 'confirmation':
      return <ModalConfirmation />;
    default:
      return <div></div>;
  }
};

const mapStateToProps = (state) => ({
  modal: state.warranty.modal,
});
export default connect(mapStateToProps)(Modal);
