import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {
  SET_ALERT,
  REMOVE_ALERT,
  GET_ITEMS,
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_LOADING_REGISTERED,
  GET_REGISTERED,
  SET_LOADING_CASE,
  SET_LOADING_WARRANTY,
  GET_CAPTCHA_SCORE,
  SET_LOADING_SUBSCRIBE,
} from './types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const setAlert =
  (msg, alertType, timeout = 3000) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

//Get All items
export const getItems = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/warranty/items');
    const data = {
      items: res.data ? res.data : [],
      categories: [],
    };
    data.items.forEach((item) => {
      if (!data.categories.includes(item.category)) {
        data.categories.push(item.category);
      }
    });

    dispatch({
      type: GET_ITEMS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ITEMS,
      payload: {
        items: [],
        categories: [],
      },
    });
  }
};

//Get All items
export const getRegistered = (email) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_REGISTERED,
  });

  try {
    const res = await axios.get(`/api/warranty/register?email=${email}`);

    dispatch({
      type: GET_REGISTERED,
      payload: res.data,
    });

    if (res.data.length) {
      return true;
    }
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
    dispatch({
      type: GET_REGISTERED,
      payload: [],
    });
  }

  return false;
};

export const openModal = (data) => (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    payload: data,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
  });
};

//Save Warranty to the database
export const registerItems = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_WARRANTY,
    payload: true,
  });
  try {
    //Create BC User
    if (data.set_password) {
      const bcBody = {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.set_password,
      };
      delete data.set_password;

      try {
        const res = await axios.post(
          '/api/warranty/customer',
          JSON.stringify(bcBody),
          config
        );
        if (res.data) {
          dispatch(setAlert(res.data.message, 'success', 10000));
        }
      } catch (err) {
        if (err.response) {
          const msg = err.response.data ? err.response.data.message : '';
          dispatch(setAlert(msg, 'error', 10000));
        } else {
          console.log(err);
        }
      }
    }

    //Register warranty
    const body = JSON.stringify(data);
    const res = await axios.post('/api/warranty/register', body, config);
    if (res.data) {
      dispatch(
        openModal({
          type: 'confirmation',
          data: res.data,
        })
      );
      dispatch({
        type: SET_LOADING_WARRANTY,
        payload: false,
      });
      return true;
    }
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
  }
  dispatch({
    type: SET_LOADING_WARRANTY,
    payload: false,
  });
  return false;
};

//Save Case to the database
export const saveCase = (data) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_CASE,
    payload: true,
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const encodedFiles = [];
  const files = data.mailData.files;

  try {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const result = await toBase64(files[i]).catch((e) => Error(e));
        if (result instanceof Error) {
          dispatch(setAlert(result.message, 'error'));
          continue;
        }
        encodedFiles.push({
          name: files[i].name,
          content: result.replace(`data:${files[i].type};base64,`, ''),
          type: files[i].type,
        });
      }
    }

    if (encodedFiles.length) data.mailData.files = encodedFiles;

    const body = JSON.stringify(data);
    const res = await axios.post('/api/warranty/case', body, config);
    if (res.data) {
      dispatch(
        openModal({
          type: 'confirmation',
          data: res.data,
        })
      );
      dispatch({
        type: SET_LOADING_CASE,
        payload: false,
      });
      return true;
    }
  } catch (err) {
    if (err.response) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    } else {
      console.log(err);
    }
  }
  dispatch({
    type: SET_LOADING_CASE,
    payload: false,
  });
  return false;
};

//Check reCaptcha score
export const checkScore = (action) => (dispatch) => {
  const getScore = () => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action })
          .then(async (token) => {
            try {
              const body = JSON.stringify({ token });
              const res = await axios.post('/api/warranty/score', body, config);

              dispatch({ type: GET_CAPTCHA_SCORE, payload: res.data });
              if (res.data < 0.5)
                dispatch(
                  setAlert(
                    'High risk activity detected. Try again later',
                    'error',
                    10000
                  )
                );
            } catch (err) {
              if (err.response) {
                const errors = err.response.data.errors;
                if (errors) {
                  errors.forEach((error) =>
                    dispatch(setAlert(error.msg, 'error'))
                  );
                }
              } else {
                console.log(err);
              }
              dispatch({ type: GET_CAPTCHA_SCORE, payload: 1 });
            }
          });
      });
    }
  };

  if (window.grecaptcha) {
    getScore();
  } else {
    setTimeout(() => {
      getScore();
    }, 300);
  }
};

//Subscribe email to the gritrgear updates
export const subscribeUser = (email) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_SUBSCRIBE,
    payload: true,
  });
  try {
    const body = JSON.stringify({ email });
    const res = await axios.post('/api/warranty/subscribe', body, config);
    if (res.data) {
      dispatch(setAlert(res.data.message, 'success', 6000));
    }
  } catch (err) {
    if (err.response) {
      const msg = err.response.data ? err.response.data.message : '';
      dispatch(setAlert(msg, 'error', 6000));
    } else {
      console.log(err);
    }
  }
  dispatch({
    type: SET_LOADING_SUBSCRIBE,
    payload: false,
  });
};
