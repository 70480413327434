import { connect } from 'react-redux';
import { RiErrorWarningLine } from 'react-icons/ri';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

const AlertBlock = ({ warranty: { alerts } }) => {
  return (
    <div className='alert-container'>
      {alerts.map((alert) => {
        let data = {
          className: '',
          header: null,
        };

        switch (alert.alertType) {
          case 'success':
            data.className = 'allert-message-success';
            data.header = (
              <p className='allert-message-header'>
                <IoIosCheckmarkCircleOutline className='allert-message-success-icon' />{' '}
                Success
              </p>
            );
            break;
          case 'error':
            data.className = 'allert-message-error';
            data.header = (
              <p className='allert-message-header'>
                <RiErrorWarningLine className='allert-message-error-icon' />{' '}
                Error
              </p>
            );
            break;
          default:
            break;
        }

        return (
          <div key={alert.id} className={`allert-message ${data.className}`}>
            {data.header}
            {alert.msg}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  warranty: state.warranty,
});

export default connect(mapStateToProps)(AlertBlock);
