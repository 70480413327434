import { useState } from 'react';
import { AiOutlineOrderedList, AiOutlineUnorderedList } from 'react-icons/ai';
import { BsTextCenter, BsTextLeft, BsTextRight } from 'react-icons/bs';

const TextEditor = ({ formData, setFormData, valid }) => {
  const [btnStatus, setBtnStatus] = useState({
    bold: false,
    italic: false,
    underline: false,
    orderedList: false,
    unorderedList: false,
    justifyLeft: false,
    justifyCenter: false,
    justifyRight: false,
  });

  const onChange = (e) => {
    setFormData({ ...formData, case_description: e.target.innerHTML });
  };

  return (
    <div className='text-editor-container'>
      <div className='text-editor-toolbar'>
        <button
          className={`text-editor-btn ${
            btnStatus.bold ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('bold');
            setBtnStatus({ ...btnStatus, bold: !btnStatus.bold });
          }}
        >
          B
        </button>
        <button
          className={`text-editor-btn italic ${
            btnStatus.italic ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('italic');
            setBtnStatus({ ...btnStatus, italic: !btnStatus.italic });
          }}
        >
          I
        </button>
        <button
          className={`text-editor-btn underlined ${
            btnStatus.underline ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('underline');
            setBtnStatus({ ...btnStatus, underline: !btnStatus.underline });
          }}
        >
          U
        </button>
        <div className='text-editor-devider'></div>
        <button
          className={`text-editor-btn ${
            btnStatus.orderedList ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('insertOrderedList');
            setBtnStatus({ ...btnStatus, orderedList: !btnStatus.orderedList });
          }}
        >
          <AiOutlineOrderedList />
        </button>
        <button
          className={`text-editor-btn ${
            btnStatus.unorderedList ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('insertUnorderedList');
            setBtnStatus({
              ...btnStatus,
              unorderedList: !btnStatus.unorderedList,
            });
          }}
        >
          <AiOutlineUnorderedList />
        </button>
        <div className='text-editor-devider'></div>
        <button
          className={`text-editor-btn ${
            btnStatus.justifyLeft ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('justifyLeft');
            setBtnStatus({
              ...btnStatus,
              justifyLeft: !btnStatus.justifyLeft,
              justifyCenter: false,
              justifyRight: false,
            });
          }}
        >
          <BsTextLeft />
        </button>
        <button
          className={`text-editor-btn ${
            btnStatus.justifyCenter ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('justifyCenter');
            setBtnStatus({
              ...btnStatus,
              justifyCenter: !btnStatus.justifyCenter,
              justifyLeft: false,
              justifyRight: false,
            });
          }}
        >
          <BsTextCenter />
        </button>
        <button
          className={`text-editor-btn ${
            btnStatus.justifyRight ? 'text-editor-btn-active' : ''
          }`}
          onClick={() => {
            document.execCommand('justifyRight');
            setBtnStatus({
              ...btnStatus,
              justifyRight: !btnStatus.justifyRight,
              justifyLeft: false,
              justifyCenter: false,
            });
          }}
        >
          <BsTextRight />
        </button>
      </div>
      <div
        className={`text-editor-box ${!valid ? '' : 'text-editor-error'}`}
        onInput={onChange}
        contentEditable={true}
      />
    </div>
  );
};

export default TextEditor;
