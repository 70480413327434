import { useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { RequiredField } from '../../utils/components';
import { validateCase } from '../../utils/constants';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { marketplaces, calcDate } from '../../utils/constants';
import CaseIssue from './CaseIssue';
import DatePicker from 'react-datepicker';

const CaseSteps = ({ data, step, setStep }) => {
  const [formData, setFormData] = useState({
    first_name: data.first_name ? data.first_name : '',
    last_name: data.last_name ? data.last_name : '',
    email: data.email ? data.email : '',
    country: data.country ? data.country : '',
    state: data.state ? data.state : '',
    city: data.city ? data.city : '',
    zip: data.zip ? data.zip : '',
    address: data.address ? data.address : '',
    order_number: data.order_number ? data.order_number : '',
    marketplace: data.marketplace ? data.marketplace : '',
    purchase_date: data.purchase_date ? new Date(data.purchase_date) : '',
    product_id: data.product_id ? data.product_id : '',
    category: data.category ? data.category : '',
  });

  const [required, setRequired] = useState({
    first_name: { filled: false, message: '' },
    last_name: { filled: false, message: '' },
    email: { filled: false, message: '' },
    product_id: { filled: false, message: '' },
    category: { filled: false, message: '' },
    order_number: { filled: false, message: '' },
    marketplace: { filled: false, message: '' },
    purchase_date: { filled: false, message: '' },
  });

  const [isOpen, setOpen] = useState(true);

  const {
    country,
    state,
    first_name,
    last_name,
    email,
    city,
    zip,
    address,
    category,
    product_id,
    order_number,
    marketplace,
    purchase_date,
  } = formData;
  const selectCountry = (value) => setFormData({ ...formData, country: value });
  const selectRegion = (value) => setFormData({ ...formData, state: value });

  const changeStep = (e) => {
    const index = +e.currentTarget.getAttribute('data-index');
    if (index) {
      if (index < step) {
        setStep(index);
        return;
      }

      const data = validateCase({ old: required, values: formData, step });
      setRequired(data.newValues);

      if (!data.preventChange) setStep(index);
    }
  };

  const changeFormData = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const openAddress = (e) => {
    setOpen(!isOpen);
    if (!country) {
      setFormData({ ...formData, country: 'United States' });
    }
  };

  const setPurchaseDate = (date) =>
    setFormData({ ...formData, purchase_date: date });

  return (
    <div className='case-steps-box'>
      <div className='case-list'>
        <div className='case-list-block'>
          <div
            className={`case-list-indicator ${
              step === 1
                ? 'case-step1'
                : step === 2
                ? 'case-step2'
                : step === 3
                ? 'case-step3'
                : 'case-step4'
            }`}
          ></div>
        </div>
        <ul className='case-list-box'>
          <li
            className={`case-list-row ${
              step === 1 ? 'case-list-row-active' : ''
            }`}
            data-index='1'
            onClick={changeStep}
          >
            Personal Details
          </li>
          <li
            className={`case-list-row ${
              step === 2 ? 'case-list-row-active' : ''
            }`}
            data-index='2'
            onClick={changeStep}
          >
            Product Information
          </li>
          <li
            className={`case-list-row ${
              step === 3 ? 'case-list-row-active' : ''
            }`}
            data-index='3'
            onClick={changeStep}
          >
            Order Details
          </li>
          <li
            className={`case-list-row ${
              step === 4 ? 'case-list-row-active' : ''
            }`}
            data-index='4'
            onClick={changeStep}
          >
            Product Issue
          </li>
        </ul>
      </div>
      <div className='register-form'>
        <div
          className={`register-form-box ${
            step === 1 ? 'register-active-form' : ''
          }`}
        >
          <div className='register-form-box-body'>
            <h3 className='register-list-row-active'>Personal Details</h3>
            <ul className='register-form-body'>
              <li className='register-form-row'>
                <label>
                  First Name
                  <RequiredField />
                </label>
                <input
                  className={`register-input ${
                    required.first_name.filled ? 'register-input-error' : ''
                  }`}
                  type='text'
                  name='first_name'
                  value={first_name}
                  onChange={changeFormData}
                />
                <p className='register-error-message'>
                  {required.first_name.message}
                </p>
              </li>
              <li className='register-form-row'>
                <label>
                  Last Name
                  <RequiredField />
                </label>
                <input
                  className={`register-input ${
                    required.last_name.filled ? 'register-input-error' : ''
                  }`}
                  type='text'
                  name='last_name'
                  value={last_name}
                  onChange={changeFormData}
                />
                <p className='register-error-message'>
                  {required.last_name.message}
                </p>
              </li>
              <li className='register-form-row'>
                <label>
                  Email
                  <RequiredField />
                </label>
                <input
                  className={`register-input ${
                    required.email.filled ? 'register-input-error' : ''
                  }`}
                  type='text'
                  name='email'
                  value={email}
                  onChange={changeFormData}
                />
                <p className='register-error-message'>
                  {required.email.message}
                </p>
              </li>
              <li className='register-form-row'>
                <div className='register-form-open' onClick={openAddress}>
                  Add an address
                  {isOpen ? (
                    <IoIosArrowDown className='register-form-open-icon' />
                  ) : (
                    <IoIosArrowForward className='register-form-open-icon' />
                  )}
                </div>
              </li>
            </ul>
            <ul
              style={{ display: isOpen ? '' : 'none' }}
              className='register-form-address'
            >
              <li className='register-form-row register-form-address-row'>
                <label>Address</label>
                <input
                  className='register-input'
                  type='text'
                  name='address'
                  value={address}
                  onChange={changeFormData}
                />
              </li>
              <li className='register-form-row register-form-address-row'>
                <label>City</label>
                <input
                  className='register-input'
                  type='text'
                  name='city'
                  value={city}
                  onChange={changeFormData}
                />
              </li>
              <li className='register-form-row register-form-address-row'>
                <label>Province/State</label>
                <RegionDropdown
                  blankOptionLabel='No Country selected'
                  defaultOptionLabel='Select a region'
                  country={country}
                  value={state}
                  onChange={selectRegion}
                  classes='register-input'
                />
              </li>
              <li className='register-form-row register-form-address-row'>
                <label>ZIP</label>
                <input
                  className='register-input'
                  type='text'
                  name='zip'
                  value={zip}
                  onChange={changeFormData}
                />
              </li>
              <li className='register-form-row register-form-address-row'>
                <label>Country</label>
                <CountryDropdown
                  defaultOptionLabel='Select a Country'
                  value={country}
                  onChange={selectCountry}
                  classes='register-input'
                />
              </li>
            </ul>
          </div>
          <div className='register-form-btns'>
            <button
              className='register-btn'
              data-index='4'
              onClick={changeStep}
            >
              NEXT
            </button>
          </div>
        </div>
        <div
          className={`register-form-box ${
            step === 2 ? 'register-active-form' : ''
          }`}
        >
          <div className='register-form-box-body'>
            <h3 className='register-list-row-active'>Product Information</h3>
            <ul className='register-form-body'>
              <li className='register-form-row'>
                <label>
                  Product Category
                  <RequiredField />
                </label>
                <select
                  className={`register-input ${
                    required.category.filled ? 'register-input-error' : ''
                  }`}
                  name='category'
                  value={category}
                  onChange={changeFormData}
                >
                  <option value='' disabled>
                    Select Category
                  </option>
                  <option value={data.category}>{data.category}</option>
                </select>
                <p className='register-error-message'>
                  {required.category.message}
                </p>
              </li>
              <li className='register-form-row'>
                <label>
                  Product Name
                  <RequiredField />
                </label>
                <select
                  className={`register-input ${
                    required.product_id.filled ? 'register-input-error' : ''
                  }`}
                  name='product_id'
                  value={product_id}
                  onChange={changeFormData}
                >
                  <option value='' disabled>
                    Select Product
                  </option>
                  <option value={data.product_id}>{data.name}</option>
                </select>
                <p className='register-error-message'>
                  {required.product_id.message}
                </p>
              </li>
            </ul>
          </div>
          <div className='register-form-btns'>
            <button
              className='register-btn register-back-btn'
              data-index='1'
              onClick={changeStep}
            >
              BACK
            </button>
            <button
              className='register-btn'
              data-index='3'
              onClick={changeStep}
            >
              NEXT
            </button>
          </div>
        </div>
        <div
          className={`register-form-box ${
            step === 3 ? 'register-active-form' : ''
          }`}
        >
          <div className='register-form-box-body'>
            <h3 className='register-list-row-active'>Order Details</h3>
            <ul className='register-form-body'>
              <li className='register-form-row'>
                <label>
                  Place of Purchase
                  <RequiredField />
                </label>
                <select
                  className={`register-input ${
                    required.marketplace.filled ? 'register-input-error' : ''
                  }`}
                  name='marketplace'
                  value={marketplace}
                  onChange={changeFormData}
                >
                  <option value='' disabled>
                    Select Marketplace
                  </option>
                  {marketplaces.map((m, i) => (
                    <option key={`market-${i}`} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
                <p className='register-error-message'>
                  {required.marketplace.message}
                </p>
              </li>
              <li className='register-form-row'>
                <label>
                  Purchase Date
                  <RequiredField />
                </label>
                <DatePicker
                  selected={purchase_date}
                  onChange={setPurchaseDate}
                  minDate={calcDate(60)}
                  maxDate={new Date()}
                  className={`register-input ${
                    required.purchase_date.filled ? 'register-input-error' : ''
                  }`}
                />
                <p className='register-error-message'>
                  {required.purchase_date.message}
                </p>
              </li>
              <li className='register-form-row'>
                <label>
                  Order Number
                  <RequiredField />
                </label>
                <input
                  className={`register-input ${
                    required.order_number.filled ? 'register-input-error' : ''
                  }`}
                  type='text'
                  name='order_number'
                  value={order_number}
                  onChange={changeFormData}
                />
                <p className='register-error-message'>
                  {required.order_number.message}
                </p>
              </li>
            </ul>
          </div>
          <div className='register-form-btns'>
            <button
              className='register-btn register-back-btn'
              data-index='2'
              onClick={changeStep}
            >
              BACK
            </button>
            <button
              className='register-btn'
              data-index='4'
              onClick={changeStep}
            >
              NEXT
            </button>
          </div>
        </div>
        <CaseIssue
          changeStep={changeStep}
          data={formData}
          step={step}
          warrantyData={data}
        />
      </div>
    </div>
  );
};

export default CaseSteps;
