export const RequiredField = () => {
  return <span className='required-icon'>*</span>;
};

export const InfoTooltip = ({ text, align, className, children }) => {
  return (
    <div className='info-icon-container'>
      <span
        style={{ verticalAlign: align ? align : 'top' }}
        className={className ? `info-icon ${className}` : 'info-icon'}
      >
        i
      </span>
      <p className='info-icon-text'>{children ? children : text}</p>
    </div>
  );
};

export const CheckBox = ({ onChange, checked, className }) => {
  return (
    <div className={`round-checkbox-container ${className ? className : ''}`}>
      <div className='round-checkbox'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange ? onChange : () => {}}
        />
        <label></label>
      </div>
    </div>
  );
};
