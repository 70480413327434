import { connect } from 'react-redux';
import { closeModal } from '../../actions/warranty';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

const ModalConfirmation = ({ modal: { data, isOpen }, closeModal }) => {
  const checkIfModal = (e) => {
    if (e.target.classList) {
      if (e.target.classList.contains('modal')) {
        closeModal();
      }
    }
  };

  const onClick = () => {
    closeModal();
  };

  return (
    <div
      className='modal'
      onClick={checkIfModal}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-container-confirm'>
        <MdClose onClick={onClick} className='modal-close-icon' />
        <IoIosCheckmarkCircleOutline className='modal-confirm-icon' />
        <p className='modal-confirm-message'>{data.message}</p>
        <div className='modal-confirm-buttons'>
          <button className='modal-btn' onClick={onClick}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.warranty.modal,
});
export default connect(mapStateToProps, { closeModal })(ModalConfirmation);
