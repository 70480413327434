import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './components/layout/Home';
import Register from './components/layout/Register';
import Case from './components/layout/Case';
import AlertBlock from './components/layout/AlertBlock';
import Modal from './components/layout/Modal';

import './css/Main.css';
import './css/Header.css';
import './css/Footer.css';
import './css/Register.css';
import './css/Case.css';
import './css/Modal.css';
import 'react-datepicker/dist/react-datepicker.css';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Header />
        <Fragment>
          <Route exact path='/' component={Home} />
          <Switch>
            <Route exact path='/register/' component={Register} />
            <Route exact path='/case/' component={Case} />
          </Switch>
        </Fragment>
        <AlertBlock />
        <Modal />
        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
