import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/warranty';
import { MdClose } from 'react-icons/md';
import amazon from '../../img/modal/amazon.jpg';
import ebay from '../../img/modal/ebay.jpg';
import gritr from '../../img/modal/gritr.jpg';
import gritroutdoors from '../../img/modal/gritroutdoors.jpg';
import gritrsport from '../../img/modal/gritrsport.jpg';
import gunbroker from '../../img/modal/gunbroker.jpg';
import walmart from '../../img/modal/walmart.jpg';
import retail from '../../img/modal/retail.jpg';
import { GoMail } from 'react-icons/go';

const ModalInfo = ({ modal: { data, isOpen }, closeModal }) => {
  const [image, setImage] = useState({ name: 'gritr', img: gritr });

  const images = useMemo(
    () => ({
      amazon,
      ebay,
      gritr,
      gritroutdoors,
      gritrsport,
      gunbroker,
      walmart,
      retail,
    }),
    []
  );

  const checkIfModal = (e) => {
    if (e.target.classList) {
      if (e.target.classList.contains('modal')) {
        closeModal();
      }
    }
  };

  const onClick = () => {
    closeModal();
  };

  const changeImage = (e) => {
    const name = e.currentTarget.getAttribute('data-img');
    if (images[name]) {
      setImage({
        name,
        img: images[name],
      });
    }
  };

  return (
    <div
      className='modal'
      onClick={checkIfModal}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className='modal-container'>
        <div className='modal-header'>
          <p className='modal-title'>HOW DO I FIND IT?</p>
          <MdClose onClick={onClick} className='modal-close-icon' />
        </div>
        <div className='modal-body'>
          <p className='modal-body-center text-bold'>
            Can’t locate your <b className='secondary-text'>order number</b>?
          </p>
          <p className='modal-body-name modal-body-center text-bold'>
            Please follow these easy steps to find it:
          </p>
          <ul className='modal-list'>
            <li>
              For the order from GRITR websites - go to the respective URL
              (website address){' '}
              <a
                href='https://gritrgear.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='register-small-link text-bold'
              >
                GritrGear.com
              </a>
              ,{' '}
              <a
                href='https://gritroutdoors.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='register-small-link text-bold'
              >
                GritrOutdoors.com
              </a>
              ,{' '}
              <a
                href='https://gritrsports.com/'
                target='_blank'
                rel='noopener noreferrer'
                className='register-small-link text-bold'
              >
                GritrSports.com
              </a>{' '}
              - open ‘Account’ and choose the ‘Orders’ section.
            </li>
            <li>
              If you made your purchase in the{' '}
              <b className='secondary-text'>
                GRITR Sports & Outdoors retail store
              </b>
              , your invoice number is the same as your order number. If you
              lost it - please call the store and give them your phone number
              and/or last name to help locate your account.
            </li>
            <li>
              If you placed the order on{' '}
              <b className='secondary-text'>Amazon</b>, go to Amazon.com - open
              ‘Account & Lists’ and click the ‘Orders’ section.
            </li>
            <li>
              For <b className='secondary-text'>eBay</b>, go to Ebay.com - open
              ‘My eBay’ and click the ‘Purchase history’ section.
            </li>
            <li>
              If you ordered from <b className='secondary-text'>Walmart</b>, go
              to Walmart.com - open ‘Account’ and click the ‘Purchase History’
              section.
            </li>
            <li>
              For <b className='secondary-text'>Gunbroker</b>, go to
              GunBroker.com - open ‘My Gunbroker’ then click ‘Buy’ and choose
              ‘Won Orders’.
            </li>
          </ul>
          <p className='modal-email-info modal-body-center'>
            <GoMail className='modal-email-icon' />
            <span>
              While you may not remember the marketplace where you placed your
              order, you should have received an{' '}
              <b className='secondary-text'>email</b> with the order number
              (click the necessary marketplace below to see a mail example).
            </span>
          </p>
          <ul className='modal-markets-list'>
            <li
              onClick={changeImage}
              data-img='gritr'
              className={image.name === 'gritr' ? 'modal-markets-active' : ''}
            >
              <span>Gritr Gear</span>
            </li>
            <li
              onClick={changeImage}
              data-img='gritroutdoors'
              className={
                image.name === 'gritroutdoors' ? 'modal-markets-active' : ''
              }
            >
              <span>Gritr Outdoors</span>
            </li>
            <li
              onClick={changeImage}
              data-img='gritrsport'
              className={
                image.name === 'gritrsport' ? 'modal-markets-active' : ''
              }
            >
              <span>Gritr Sports</span>
            </li>
            <li
              onClick={changeImage}
              data-img='retail'
              className={image.name === 'retail' ? 'modal-markets-active' : ''}
            >
              <span>GRITR Sports & Outdoors retail store</span>
            </li>
            <li
              onClick={changeImage}
              data-img='amazon'
              className={image.name === 'amazon' ? 'modal-markets-active' : ''}
            >
              <span>Amazon</span>
            </li>
            <li
              onClick={changeImage}
              data-img='ebay'
              className={image.name === 'ebay' ? 'modal-markets-active' : ''}
            >
              <span>eBay</span>
            </li>
            <li
              onClick={changeImage}
              data-img='walmart'
              className={image.name === 'walmart' ? 'modal-markets-active' : ''}
            >
              <span>Walmart</span>
            </li>
            <li
              onClick={changeImage}
              data-img='gunbroker'
              className={
                image.name === 'gunbroker' ? 'modal-markets-active' : ''
              }
            >
              <span>Gunbroker</span>
            </li>
          </ul>
          <img
            src={image.img}
            className='modal-info-img'
            alt='How to find order number'
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modal: state.warranty.modal,
});
export default connect(mapStateToProps, { closeModal })(ModalInfo);
