import { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TextEditor from './TextEditor';
import { setAlert, saveCase } from '../../actions/warranty';
import { RequiredField } from '../../utils/components';
import {
  validateCase,
  formatMySQLdate,
  getEmailBody,
  fileTypes,
  emailReceiver,
} from '../../utils/constants';
import { TiDeleteOutline } from 'react-icons/ti';

const CaseIssue = ({
  data,
  changeStep,
  step,
  warrantyData,
  warranty: { loadingCase },
  setAlert,
  saveCase,
}) => {
  const [required, setRequired] = useState({
    case_description: { filled: false, message: '' },
    files: { filled: false, message: '' },
  });
  const [formData, setFormData] = useState({
    case_description: '',
    redirect: null,
  });
  const [files, setAttachments] = useState([]);

  const { redirect, case_description } = formData;

  const fileValidation = (allFiles) => {
    const validated = [];

    allFiles.forEach((file) => {
      let isValidType = false;
      let isValidSize = false;
      fileTypes.forEach((type) => {
        if (file.type.indexOf(type) >= 0) {
          isValidType = true;
        }
      });
      if (file.size <= 5160000) isValidSize = true; // <= 5 mb
      if (!isValidType) {
        setAlert(`Incorrect file ${file.name} type`, 'error');
      }
      if (!isValidSize) {
        setAlert(`File ${file.name} size more than 5 Mb`, 'error');
      }

      if (isValidType && isValidSize) {
        validated.push(file);
      }
    });
    if (validated.length) {
      if (validated.length + files.length > 5) {
        setAlert(`Maximum 5 files attached`, 'error');
        return;
      }
      setAttachments([...files, ...validated]);
    }
  };

  const getFile = (e) => {
    if (!e.target.files) return;
    const allFiles = [...e.target.files];
    if (!allFiles.length) return;
    fileValidation(allFiles);
  };

  const deleteFile = (e) => {
    const fileIndex = +e.currentTarget.getAttribute('data-index');
    setAttachments(files.filter((file, i) => i !== fileIndex));
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      fileValidation([...e.dataTransfer.files]);
    }

    if (e.currentTarget.classList.contains('dragable-box')) {
      e.currentTarget.classList.remove('dragable-box');
    }
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragEnter = (e) => {
    if (!e.currentTarget.classList.contains('dragable-box')) {
      e.currentTarget.classList.add('dragable-box');
    }
  };
  const onDragLeave = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;

    if (!e.target.classList.contains('case-addfile-box')) return;
    if (e.currentTarget.classList.contains('dragable-box')) {
      e.currentTarget.classList.remove('dragable-box');
    }
  };

  const createCase = async (e) => {
    const validateData = validateCase({
      old: required,
      values: formData,
      step,
    });
    setRequired(
      files.length
        ? validateData.newValues
        : {
            ...validateData.newValues,
            files: { filled: true, message: 'Please, add at least 1 photo' },
          }
    );

    if (validateData.preventChange || !files.length) return;

    const body = getEmailBody({
      ...data,
      case_description,
      product_sku: warrantyData.sku,
      product_name: warrantyData.name,
    });

    const mailData = {
      email: emailReceiver,
      subject: 'New case from the Gritr Warranty Page',
      body,
      type: 'html',
      files,
    };

    const caseFields = [
      'product_id',
      'address',
      'city',
      'country',
      'state',
      'zip',
    ];
    const caseData = {
      date: formatMySQLdate(new Date()),
      case_description,
      warranty_id: warrantyData.id,
    };
    caseFields.forEach((field) => {
      if (data[field]) caseData[field] = data[field];
    });

    const res = await saveCase({ mailData, caseData });
    if (res) {
      setFormData({ ...formData, redirect: <Redirect to='/' /> });
    }
  };

  if (redirect) return redirect;

  return (
    <div
      className={`register-form-box ${
        step === 4 ? 'register-active-form' : ''
      }`}
    >
      <h3 className='register-list-row-active'>Product Issue</h3>
      <ul className='case-issue-list'>
        <li className='register-form-row'>
          <label>
            Warranty Request Description
            <RequiredField />
          </label>
          <TextEditor
            valid={required.case_description.filled}
            formData={formData}
            setFormData={setFormData}
          />
          <p className='register-error-message'>
            {required.case_description.message}
          </p>
        </li>
        <li className='register-form-row'>
          <label>
            Attach Photos
            <RequiredField />
          </label>
          <div className='case-addfile-container'>
            <div className='case-addfile-main'>
              <div
                className={`case-addfile-box ${
                  required.files.filled ? 'case-addfile-box-error' : ''
                }`}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
              >
                <input
                  onChange={getFile}
                  type='file'
                  multiple
                  id='add-files'
                  accept='image/*'
                  disabled={loadingCase}
                />
                <label htmlFor='add-files' className='case-upload-button'>
                  Add file
                </label>
                <span>or drop files here</span>
              </div>
            </div>
            <div className='case-attached-box'>
              {files.map((file, i) => (
                <p className='case-attached-file' key={`attachment-${i}`}>
                  {file.name}
                  <TiDeleteOutline
                    data-index={i}
                    onClick={deleteFile}
                    className='case-attached-delete'
                  />
                </p>
              ))}
            </div>
          </div>
          <p className='register-error-message'>{required.files.message}</p>
        </li>
      </ul>
      <div className='register-form-btns'>
        <button
          className='register-btn register-back-btn'
          data-index='3'
          onClick={changeStep}
        >
          BACK
        </button>
        <button
          className='register-btn'
          onClick={createCase}
          disabled={loadingCase}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  warranty: state.warranty,
});

export default connect(mapStateToProps, { setAlert, saveCase })(CaseIssue);
