export const validateRegister = ({ old, values, step }) => {
  const requiredStep1 = ['first_name', 'last_name', 'email'];
  const requiredStep2 = ['id', 'category'];
  const requiredStep3 = ['marketplace', 'purchase_date', 'order_number'];
  let required = [];
  switch (step) {
    case 1:
      required = requiredStep1;
      break;
    case 2:
      required = requiredStep2;
      break;
    case 3:
      required = values.needUser
        ? [...requiredStep3, 'set_password', 'password2']
        : requiredStep3;
      break;
    default:
      break;
  }

  const passwordCheck = (password) => {
    return /\d/.test(password) && /\p{L}/u.test(password);
  };

  const isArray = Array.isArray(old);
  let newValues = isArray ? [] : {};
  let found = false;
  if (isArray) {
    newValues = old.map((product, i) => {
      const item = {};
      for (const key in product) {
        if (required.includes(key)) {
          if (!values[i][key]) {
            item[key] = { filled: true, message: 'empty field' };
            found = true;
            continue;
          }
        }

        item[key] = { filled: false, message: '' };
      }
      return item;
    });
  } else {
    for (const key in old) {
      if (required.includes(key)) {
        if (!values[key]) {
          newValues[key] = { filled: true, message: 'empty field' };
          found = true;
          continue;
        } else if (key === 'email') {
          if (!values[key].includes('@') || !values[key].includes('.')) {
            newValues[key] = {
              filled: true,
              message: 'incorrect email address',
            };
            found = true;
            continue;
          }
        } else if (key === 'set_password' || key === 'password2') {
          if (values.set_password !== values.password2) {
            newValues[key] = {
              filled: true,
              message: 'passwords do not match',
            };
            found = true;
            continue;
          }

          if (values[key].length < 7) {
            newValues[key] = {
              filled: true,
              message: 'password must be at least 7 characters',
            };
            found = true;
            continue;
          }

          if (!passwordCheck(values[key])) {
            newValues[key] = {
              filled: true,
              message:
                'password must contain both alphabetic and numeric characters',
            };
            found = true;
            continue;
          }
        }
      }

      newValues[key] = { filled: false, message: '' };
    }
  }

  return { newValues, preventChange: found };
};

export const validateCase = ({ old, values, step }) => {
  const requiredStep1 = ['first_name', 'last_name', 'email', 'country'];
  const requiredStep2 = ['product_id', 'category'];
  const requiredStep3 = ['marketplace', 'purchase_date', 'order_number'];
  let required = [];
  switch (step) {
    case -1:
      required = ['email'];
      break;
    case 0:
      required = ['item'];
      break;
    case 1:
      required = requiredStep1;
      break;
    case 2:
      required = requiredStep2;
      break;
    case 3:
      required = requiredStep3;
      break;
    case 4:
      required = ['case_description'];
      break;
    default:
      break;
  }

  let newValues = {};
  let found = false;

  for (const key in old) {
    if (required.includes(key)) {
      if (!values[key]) {
        newValues[key] = { filled: true, message: 'empty field' };
        found = true;
        continue;
      } else if (key === 'email') {
        if (!values[key].includes('@') || !values[key].includes('.')) {
          newValues[key] = {
            filled: true,
            message: 'incorrect email address',
          };
          found = true;
          continue;
        }
      }
    }

    newValues[key] = { filled: false, message: '' };
  }

  return { newValues, preventChange: found };
};

export const marketplaces = [
  'Amazon',
  'eBay',
  'Walmart',
  'Retail Store',
  'GunBroker',
  'GritrSports.com',
  'GritrOutdoors.com',
  'GritrGear.com',
];

export const formatMySQLdate = (custom) => {
  return custom.toISOString().slice(0, 19).replace('T', ' ');
};

export const formatUSdate = (d) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  }).format(d);

export const getEmailBody = (data) => {
  const headers = [
    { key: 'email', label: 'Customer Email' },
    { key: 'first_name', label: 'First Name' },
    { key: 'last_name', label: 'Last Name' },
    { key: 'product_sku', label: 'Product SKU' },
    { key: 'product_name', label: 'Product Name' },
    { key: 'marketplace', label: 'Marketplace' },
    { key: 'purchase_date', label: 'Purchase Date' },
    { key: 'order_number', label: 'Order Number' },
    { key: 'case_description', label: 'Case Description' },
  ];

  let body = `<table style="border: 1px solid black;border-collapse: collapse;padding: 5px 10px; font-size: 14px;">
          <tr style="background-color: #ccc;">
            <th style="text-align: center;padding: 5px 10px;border: 1px solid black;">Name</th>
            <th style="text-align: center;padding: 5px 10px;border: 1px solid black;">Value</th>
          </tr>`;

  headers.forEach((h) => {
    if (data[h.key]) {
      body += `<tr>
        <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${
          h.label
        }</td>
        <td style="padding: 5px 10px;border: 1px solid black; text-align: left;">${
          h.key === 'purchase_date' ? formatUSdate(data[h.key]) : data[h.key]
        }</td>
      </tr>`;
    }
  });

  body += `</table><span style="opacity: 0">${new Date()}</span>`;
  return body;
};

export const fileTypes = ['image/'];
export const emailReceiver = 'cs@gritrgear.com';

export const calcDate = (days) => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - days));
};

export const headerNav = [
  {
    link: 'https://gritrgear.com/storage/',
    name: 'Storage',
    values: [
      { link: 'https://gritrgear.com/storage/belts/', name: 'Belts' },
      {
        link: 'https://gritrgear.com/storage/bags-cases/',
        name: 'Bags & Cases',
      },
      { link: 'https://gritrgear.com/storage/holsters/', name: 'Holsters' },
    ],
  },
  {
    link: 'https://gritrgear.com/gear/',
    name: 'Gear',
    values: [
      { link: 'https://gritrgear.com/gear/tools/', name: 'Tools' },
      {
        link: 'https://gritrgear.com/gear/knives/',
        name: 'Knives',
      },
      {
        link: 'https://gritrgear.com/gear/cleaning-kits/',
        name: 'Cleaning Kits',
      },
      { link: 'https://gritrgear.com/gear/lights/', name: 'Lights' },
    ],
  },
  {
    link: 'https://gritrgear.com/optics/',
    name: 'Optics',
    values: [],
  },
  {
    link: 'https://gritrgear.com/camping/',
    name: 'Camping',
    values: [
      { link: 'https://gritrgear.com/camping/cups/', name: 'Cups' },
      {
        link: 'https://gritrgear.com/camping/fire-starters/',
        name: 'Fire Starters',
      },
    ],
  },
  {
    link: 'https://gritrgear.com/sale/',
    name: 'Sale',
    values: [],
  },
  {
    link: '/',
    name: 'Warranty',
    type: 'internal',
    values: [],
  },
];
