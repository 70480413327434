export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GET_ITEMS = 'GET_ITEMS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const SET_LOADING_REGISTERED = 'SET_LOADING_REGISTERED';
export const GET_REGISTERED = 'GET_REGISTERED';
export const SET_LOADING_CASE = 'SET_LOADING_CASE';
export const SET_LOADING_WARRANTY = 'SET_LOADING_WARRANTY';
export const GET_CAPTCHA_SCORE = 'GET_CAPTCHA_SCORE';
export const SET_LOADING_SUBSCRIBE = 'SET_LOADING_SUBSCRIBE';
