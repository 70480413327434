import { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../utils/components';
import { getRegistered, checkScore } from '../../actions/warranty';
import { validateCase, formatUSdate } from '../../utils/constants';
import { MdArrowForwardIos } from 'react-icons/md';
import CaseSteps from '../case/CaseSteps';

const Case = ({
  warranty: { loadingRegistered, registered, score, getScore },
  getRegistered,
  checkScore,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!getScore) checkScore('submit');
  }, [checkScore, getScore]);

  const [isRegistered, setRegistered] = useState({
    yes: false,
    no: false,
  });
  const [step, setStep] = useState(-1);
  const [formData, setFormData] = useState({
    email: '',
    item: null,
    isForm: false,
  });
  const [items, setItem] = useState([]);

  const [required, setRequired] = useState({
    email: { filled: false, message: '' },
    item: { filled: false, message: '' },
  });

  const { email, item, isForm } = formData;

  useEffect(() => {
    setItem(registered.map((r) => false));
  }, [registered]);

  const changeRegistered = (e) => {
    const check = e.currentTarget.getAttribute('name');
    const newRegistered = { yes: false, no: false };
    if (check) {
      newRegistered[check] = !registered[check];
    }
    setRegistered(newRegistered);
  };

  const changeFormData = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const checkEmail = async (e) => {
    setStep(-1);
    const data = validateCase({ old: required, values: formData, step: -1 });
    setRequired(data.newValues);
    if (!data.preventChange) {
      const res = await getRegistered(email);
      if (!res) {
        setRequired({
          ...required,
          email: {
            filled: true,
            message:
              'A user with that email was not found. Please try again or fill in the standard form.',
          },
        });
      } else {
        setStep(0);
      }
    }
  };

  const selectItem = (e) => {
    const id = +e.currentTarget.getAttribute('data-id');
    const index = +e.currentTarget.getAttribute('data-index');
    if (id) {
      setFormData({
        ...formData,
        item: !items[index] ? registered.find((item) => item.id === id) : null,
      });
      setItem(
        items.map((item, i) => {
          if (i === index) {
            return !item;
          }
          return false;
        })
      );
    }
  };

  const changeStep = (e) => {
    const index = +e.currentTarget.getAttribute('data-index');
    if (index) {
      if (index < step) {
        setStep(index);
        return;
      }

      const data = validateCase({ old: required, values: formData, step });
      setRequired(data.newValues);

      if (!data.preventChange) {
        setStep(index);
        if (index === 1) setFormData({ ...formData, isForm: true });
      }
    }
  };

  return (
    <div className='register-container'>
      <div className='register-steps-header'>
        <Link to='/' className='breadcrumbs-link'>
          Warranty
        </Link>
        <MdArrowForwardIos className='register-header-icon' />
        PRODUCT ISSUE
        <MdArrowForwardIos className='register-header-icon' />
      </div>
      <div className='case-container'>
        {isForm ? (
          <CaseSteps step={step} setStep={setStep} data={item ? item : {}} />
        ) : (
          <Fragment>
            <div className='case-box'>
              <h2 className='case-box-header'>
                Do you have registered products?
              </h2>
              <div className='case-box-options-list'>
                <div
                  className='case-box-option'
                  name='yes'
                  onClick={changeRegistered}
                >
                  <CheckBox
                    checked={isRegistered.yes}
                    className='case-check-container'
                  />
                  Yes
                </div>
                <div
                  className='case-box-option'
                  name='no'
                  onClick={changeRegistered}
                >
                  <CheckBox
                    checked={isRegistered.no}
                    className='case-check-container'
                  />
                  No
                </div>
              </div>
            </div>
            <div
              className='case-box'
              style={{
                display: isRegistered.yes ? '' : 'none',
              }}
            >
              <div className='case-box-email'>
                <div className='case-email-input'>
                  <span className='case-box-label'>Enter Your Email</span>
                  <input
                    className={`register-input`}
                    type='text'
                    name='email'
                    value={email}
                    onChange={changeFormData}
                  />
                  <p className='case-email-message'>{required.email.message}</p>
                </div>
                <button
                  className='register-btn case-email-btn'
                  onClick={checkEmail}
                  disabled={loadingRegistered || score < 0.5 ? true : false}
                >
                  CHECK MY ITEMS
                </button>
              </div>
            </div>
            <div
              style={{
                display: isRegistered.no || required.email.filled ? '' : 'none',
              }}
              className='case-register-btn'
            >
              <Link to='/register/' className='home-actions-btn'>
                FILL IN THE FORM
              </Link>
            </div>
            <div style={{ display: step === 0 ? 'block' : 'none' }}>
              <div className='case-items'>
                {loadingRegistered ? (
                  <div>Loader</div>
                ) : (
                  registered.map((r, i) => (
                    <div
                      key={`item-row-${i}`}
                      className={`case-items-row ${
                        item
                          ? item.id === r.id
                            ? 'case-items-row-selected'
                            : ''
                          : ''
                      }`}
                      data-id={r.id}
                      data-index={i}
                      onClick={selectItem}
                    >
                      <CheckBox checked={items[i] ? items[i] : false} />
                      <div className='case-items-row-options'>
                        <p className='case-items-row-option'>
                          <span className='case-items-row-option-header'>
                            Name:
                          </span>
                          <span className='case-items-row-option-value'>
                            {r.name}
                          </span>
                        </p>
                        <p className='case-items-row-option'>
                          <span className='case-items-row-option-header'>
                            Purchase date:
                          </span>
                          <span className='case-items-row-option-value'>
                            {formatUSdate(new Date(r.purchase_date))}
                          </span>
                        </p>
                        <p className='case-items-row-option'>
                          <span className='case-items-row-option-header'>
                            Place of Purchase:
                          </span>
                          <span className='case-items-row-option-value'>
                            {r.marketplace}
                          </span>
                        </p>
                        <p className='case-items-row-option'>
                          <span className='case-items-row-option-header'>
                            Order Number:
                          </span>
                          <span className='case-items-row-option-value'>
                            {r.order_number}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className='case-form-btns'>
                <p
                  className='case-form-btns-error'
                  style={{ display: required.item.filled ? '' : 'none' }}
                >
                  Please, select an item
                </p>
                <button
                  className='register-btn'
                  data-index='1'
                  onClick={changeStep}
                >
                  NEXT
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  warranty: state.warranty,
});

export default connect(mapStateToProps, { getRegistered, checkScore })(Case);
