import logo from '../../img/logo.png';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { BsTextLeft, BsPencilSquare, BsBag } from 'react-icons/bs';
import { AiOutlineUnlock } from 'react-icons/ai';
import { HiOutlineUser } from 'react-icons/hi';
import { BiHeart, BiSearch } from 'react-icons/bi';
import { IoShuffleSharp } from 'react-icons/io5';
import { headerNav } from '../../utils/constants';

const Header = () => {
  return (
    <div className='header-container'>
      <a href='https://gritrgear.com/'>
        <BsTextLeft className='header-menu-icon' />
      </a>
      <a href='https://gritrgear.com/'>
        <img src={logo} alt='logo' className='header-logo' />
      </a>
      <ul className='header-list'>
        {headerNav.map((h, i) => (
          <li key={`${h.name}-${i}`} className='header-option'>
            {h.type === 'internal' ? (
              <Link to={h.link} className='header-option-link'>
                {h.name}
                {h.values.length ? (
                  <RiArrowDownSLine className='header-option-icon' />
                ) : null}
              </Link>
            ) : (
              <a href={h.link} className='header-option-link'>
                {h.name}
                {h.values.length ? (
                  <RiArrowDownSLine className='header-option-icon' />
                ) : null}
              </a>
            )}
            {h.values.length ? (
              <ul className='header-option-list'>
                {h.values.map((value, j) => (
                  <li key={`${value.name}-${j}`}>
                    <a href={value.link}>{value.name}</a>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
      <ul className='header-left-list'>
        <li className='header-option header-option-right'>
          <a
            href='https://gritrgear.com/account.php'
            className='header-option-link'
          >
            <HiOutlineUser className='header-left-icon' />
          </a>
          <ul className='header-option-list'>
            <li>
              <a href='https://gritrgear.com/login.php'>
                <AiOutlineUnlock className='header-option-list-icon' />
                Sign in
              </a>
            </li>
            <li>
              <a href='https://gritrgear.com/login.php?action=create_account'>
                <BsPencilSquare className='header-option-list-icon' />
                Register
              </a>
            </li>
            <li>
              <a href='https://gritrgear.com/wishlist.php'>
                <BiHeart className='header-option-list-icon' />
                Wishlist
              </a>
            </li>
          </ul>
        </li>
        <li className='header-option header-option-right'>
          <a href='https://gritrgear.com/' className='header-option-link'>
            <IoShuffleSharp className='header-left-icon' />
          </a>
        </li>
        <li className='header-option header-option-right header-left-icon-hide'>
          <a href='https://gritrgear.com/' className='header-option-link'>
            <BiSearch className='header-left-icon header-left-icon-secondary' />
          </a>
        </li>
        <li className='header-option header-option-right'>
          <a
            href='https://gritrgear.com/cart.php'
            className='header-option-link'
          >
            <BsBag className='header-left-icon' />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Header;
