import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkScore } from '../../actions/warranty';

import banner from '../../img/banner_warranty.png';
import infinity from '../../img/infinity.png';
import checkmark from '../../img/checkmark.png';
import calendar from '../../img/calendar.png';
import cross from '../../img/cross.png';
import broken from '../../img/broken.png';
import battery from '../../img/battery.png';
import box from '../../img/box.png';
import barcode from '../../img/barcode.png';
import like from '../../img/like.png';
import warning from '../../img/warning.png';

const Home = ({ warranty: { getScore }, checkScore }) => {
  const info = [
    {
      text: `Products used for more than 1 year and weren't registered within 60 days of placing your order.`,
      img: calendar,
    },
    { text: 'Lost, stolen or deliberately damaged products.', img: cross },
    { text: 'Products with cosmetic damages.', img: broken },
    {
      text: 'Any consumables (batteries, cartridges, fuel, etc.)',
      img: battery,
    },
    { text: 'Open boxes', img: box },
    {
      text: 'If there is no information of placing order date and order number for products purchased with 3rd parties.',
      img: barcode,
    },
  ];

  useEffect(() => {
    if (!getScore) checkScore('homepage');
  }, [getScore, checkScore]);

  return (
    <div className='home-container'>
      <img src={banner} alt='warranty logo' className='home-logo' />
      <section className='home-description'>
        <p className='home-description-row'>
          <img
            src={infinity}
            alt='infinity icon'
            className='home-description-icon'
          />
          <span>
            At GRITR, we believe limited warranties lack real grit. So we’ve
            created our UNLIMITED GRIT Warranty.
          </span>
        </p>
        <p className='home-description-row'>
          <img
            src={checkmark}
            alt='checkmark icon'
            className='home-description-icon'
          />
          <span>
            Yes, unlimited means UNLIMITED - no matter what happens - we got
            your back! Should your product become damaged or defective in the
            course of its intended use, GRITR will repair it at no cost to you.
            In the event your product can’t be repaired, we will replace it with
            an equal or superior model.
          </span>
        </p>
      </section>
      <section className='home-actions'>
        <div className='home-actions-box'>
          <div className='home-actions-container'>
            <span>
              Are you ready to get GRITR “Unlimited Grit” Lifetime Warranty? All
              you need to do is to fill in the UNLIMITED GRIT Warranty form.
            </span>
            <Link to='/register/' className='home-actions-btn'>
              GET LIFETIME WARRANTY
            </Link>
            <span>
              But do not forget: you are allowed to register UNLIMITED GRIT
              Warranty only within 60 days of placing your order.
            </span>
          </div>
        </div>
        <div className='home-actions-box'>
          <div className='home-actions-container'>
            <span>
              Want to know what to do if you need to repair or replace the
              product? It's easy - just fill in the form and attach photos of a
              defect.
            </span>
            <Link to='/case/' className='home-actions-btn'>
              I HAVE A PRODUCT ISSUE
            </Link>
            <span>
              Our specialists will review your submission and contact you with
              the next steps.
            </span>
          </div>
        </div>
      </section>
      <div className='home-label'>UNLIMITED GRIT WARRANTY DOES NOT COVER:</div>
      <section className='home-info'>
        {info.map((data, i) => (
          <div className='home-info-box' key={i}>
            <img src={data.img} alt={data.text} className='home-info-icon' />
            <span>{data.text}</span>
          </div>
        ))}
      </section>
      <section className='home-description home-description2'>
        <p className='home-description-row'>
          <img
            src={warning}
            alt='warning icon'
            className='home-description-icon'
          />
          <span>
            Remember: before replacing the product, our specialists may ask you
            to send your product to us for an evaluation.
          </span>
        </p>
        <p className='home-description-row'>
          <img src={like} alt='like icon' className='home-description-icon' />
          <span>
            Also, let’s be friends. Do not try to cheat - sending in counterfeit
            items only hurts other people by increasing our costs of support.
            Our warranty process is customer oriented and transparent and we
            expect you to be honest too.
          </span>
        </p>
      </section>
      <div className='home-label home-label-gritr'>
        <span>
          <span className='home-black-text'>G</span>OOD
        </span>
        <span>
          <span className='home-black-text'>R</span>ELATIONSHIP
        </span>
        <span>
          <span className='home-black-text'>I</span>S
        </span>
        <span>
          <span className='home-black-text'>T</span>RUSTING
        </span>
        <span>
          <span className='home-black-text'>R</span>ELATIONSHIP
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  warranty: state.warranty,
});

export default connect(mapStateToProps, { checkScore })(Home);
