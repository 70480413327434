import {
  SET_ALERT,
  REMOVE_ALERT,
  GET_ITEMS,
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_LOADING_REGISTERED,
  GET_REGISTERED,
  SET_LOADING_CASE,
  SET_LOADING_WARRANTY,
  GET_CAPTCHA_SCORE,
  SET_LOADING_SUBSCRIBE,
} from '../actions/types';

const initialState = {
  alerts: [],
  items: [],
  categories: [],
  registered: [],
  modal: {
    type: null,
    isOpen: false,
    data: null,
  },
  score: 1,
  getScore: false,
  loadingItems: true,
  loadingRegistered: false,
  loadingCase: false,
  loadingWarranty: false,
  loadingSubscribe: false,
};

export default function warranty(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== payload),
      };
    case GET_ITEMS:
      return {
        ...state,
        ...payload,
        loadingItems: false,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...payload,
          isOpen: true,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: {
          type: null,
          isOpen: false,
          data: null,
        },
      };
    case SET_LOADING_REGISTERED:
      return {
        ...state,
        loadingRegistered: true,
      };
    case GET_REGISTERED:
      return {
        ...state,
        registered: payload,
        loadingRegistered: false,
      };
    case SET_LOADING_CASE:
      return {
        ...state,
        loadingCase: payload,
      };

    case SET_LOADING_WARRANTY:
      return {
        ...state,
        loadingWarranty: payload,
      };
    case GET_CAPTCHA_SCORE:
      return {
        ...state,
        score: payload,
        getScore: true,
      };
    case SET_LOADING_SUBSCRIBE:
      return {
        ...state,
        loadingSubscribe: payload,
      };

    default:
      return state;
  }
}
