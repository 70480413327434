import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {
  getItems,
  registerItems,
  openModal,
  checkScore,
} from '../../actions/warranty';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { MdArrowForwardIos } from 'react-icons/md';
import { RequiredField, CheckBox, InfoTooltip } from '../../utils/components';
import { validateRegister } from '../../utils/constants';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';
import { marketplaces, formatMySQLdate, calcDate } from '../../utils/constants';
import DatePicker from 'react-datepicker';

const Register = ({
  warranty: {
    items,
    categories,
    score,
    loadingWarranty,
    getScore,
    loadingItems,
  },
  getItems,
  registerItems,
  openModal,
  checkScore,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!getScore) checkScore('submit');
  }, [checkScore, getScore]);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country: '',
    state: '',
    city: '',
    zip: '',
    address: '',
    order_number: '',
    marketplace: '',
    purchase_date: '',
    set_password: '',
    password2: '',
    needUser: false,
    redirect: null,
  });

  const [products, setProducts] = useState([{ category: '', id: '' }]);
  const [required, setRequired] = useState({
    first_name: { filled: false, message: '' },
    last_name: { filled: false, message: '' },
    email: { filled: false, message: '' },
    order_number: { filled: false, message: '' },
    marketplace: { filled: false, message: '' },
    purchase_date: { filled: false, message: '' },
    set_password: { filled: false, message: '' },
    password2: { filled: false, message: '' },
  });

  const [requiredProducts, setRequiredProducts] = useState([
    {
      category: { filled: false, message: '' },
      id: { filled: false, message: '' },
    },
  ]);

  const [step, setStep] = useState(1);
  const [isOpen, setOpen] = useState(false);

  const {
    country,
    state,
    first_name,
    last_name,
    email,
    city,
    zip,
    address,
    order_number,
    marketplace,
    purchase_date,
    set_password,
    password2,
    needUser,
    redirect,
  } = formData;
  const selectCountry = (value) => setFormData({ ...formData, country: value });
  const selectRegion = (value) => setFormData({ ...formData, state: value });

  const changeStep = (e) => {
    const index = +e.currentTarget.getAttribute('data-index');
    if (index) {
      if (index < step) {
        setStep(index);
        return;
      }

      if (index === 2 && !items.length) {
        getItems();
      }

      let data = {};
      if (step !== 2) {
        data = validateRegister({ old: required, values: formData, step });
        setRequired(data.newValues);
      } else {
        data = validateRegister({
          old: requiredProducts,
          values: products,
          step,
        });
        setRequiredProducts(data.newValues);
      }
      if (!data.preventChange) setStep(index);
    }
  };

  const changeItem = (e) => {
    const index = +e.target.getAttribute('data-index');
    if (index >= 0) {
      const product =
        e.target.name === 'id'
          ? items.find((p) => p.id === +e.target.value)
          : null;
      if (product) {
        setProducts(
          products.map((item, i) => {
            if (i === index) {
              item[e.target.name] = e.target.value;
              item.category = product.category;
            }
            return item;
          })
        );
      } else {
        setProducts(
          products.map((item, i) => {
            if (i === index) {
              item[e.target.name] = e.target.value;
            }
            return item;
          })
        );
      }
    }
  };

  const changeFormData = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const openAddress = (e) => {
    setOpen(!isOpen);
    setFormData({ ...formData, country: 'United States' });
  };

  const deleteProduct = (e) => {
    const index = +e.currentTarget.getAttribute('data-index');
    if (index) {
      setProducts(products.filter((p, i) => i !== index));
      setRequiredProducts(requiredProducts.filter((p, i) => i !== index));
    }
  };

  const addProduct = (e) => {
    setProducts([...products, { category: '', id: '' }]);
    setRequiredProducts([
      ...requiredProducts,
      {
        category: { filled: false, message: '' },
        id: { filled: false, message: '' },
      },
    ]);
  };

  const createUser = (e) =>
    setFormData({ ...formData, needUser: !formData.needUser });

  const setPurchaseDate = (date) =>
    setFormData({ ...formData, purchase_date: date });

  const registerItem = async (e) => {
    const data = validateRegister({ old: required, values: formData, step });
    setRequired(data.newValues);

    if (data.preventChange) return;

    const requestData = {
      products,
      date: formatMySQLdate(new Date()),
    };
    const notData = ['needUser', 'password2', 'redirect'];
    for (const key in formData) {
      if (formData[key] && !notData.includes(key)) {
        requestData[key] =
          key === 'purchase_date'
            ? formatMySQLdate(new Date(formData[key].setHours(10, 0, 0, 0)))
            : formData[key];
      }
    }

    const res = await registerItems(requestData);
    if (res) {
      setFormData({ ...formData, redirect: <Redirect to='/' /> });
    }
  };

  if (redirect) return redirect;

  return (
    <div className='register-container'>
      <div className='register-steps-header'>
        <Link to='/' className='breadcrumbs-link'>
          Warranty
        </Link>
        <MdArrowForwardIos className='register-header-icon' />
        WARRANTY REGISTRATION
        <MdArrowForwardIos className='register-header-icon' />
      </div>
      <div className='register-steps-box'>
        <div className='register-list'>
          <div className='register-list-block'>
            <div
              className={`register-list-indicator ${
                step === 1
                  ? 'register-step1'
                  : step === 2
                  ? 'register-step2'
                  : 'register-step3'
              }`}
            ></div>
          </div>
          <ul className='register-list-box'>
            <li
              className={`register-list-row register-list-toprow ${
                step === 1 ? 'register-list-row-active' : ''
              }`}
              data-index='1'
              onClick={changeStep}
            >
              Personal Details
            </li>
            <li
              className={`register-list-row ${
                step === 2 ? 'register-list-row-active' : ''
              }`}
              data-index='2'
              onClick={changeStep}
            >
              Product Information
            </li>
            <li
              className={`register-list-row register-list-bottomrow ${
                step === 3 ? 'register-list-row-active' : ''
              }`}
              data-index='3'
              onClick={changeStep}
            >
              Order Details
            </li>
          </ul>
        </div>
        <div className='register-form'>
          <div
            className={`register-form-box ${
              step === 1 ? 'register-active-form' : ''
            }`}
          >
            <div className='register-form-box-body'>
              <h3 className='register-list-row-active'>Personal Details</h3>
              <ul className='register-form-body'>
                <li className='register-form-row'>
                  <label>
                    First Name
                    <RequiredField />
                  </label>
                  <input
                    className={`register-input ${
                      required.first_name.filled ? 'register-input-error' : ''
                    }`}
                    type='text'
                    name='first_name'
                    value={first_name}
                    onChange={changeFormData}
                  />
                  <p className='register-error-message'>
                    {required.first_name.message}
                  </p>
                </li>
                <li className='register-form-row'>
                  <label>
                    Last Name
                    <RequiredField />
                  </label>
                  <input
                    className={`register-input ${
                      required.last_name.filled ? 'register-input-error' : ''
                    }`}
                    type='text'
                    name='last_name'
                    value={last_name}
                    onChange={changeFormData}
                  />
                  <p className='register-error-message'>
                    {required.last_name.message}
                  </p>
                </li>
                <li className='register-form-row'>
                  <label>
                    Email
                    <RequiredField />
                  </label>
                  <input
                    className={`register-input ${
                      required.email.filled ? 'register-input-error' : ''
                    }`}
                    type='text'
                    name='email'
                    value={email}
                    onChange={changeFormData}
                  />
                  <p className='register-error-message'>
                    {required.email.message}
                  </p>
                </li>
                <li className='register-form-row'>
                  <div className='register-form-open' onClick={openAddress}>
                    Add an address
                    {isOpen ? (
                      <IoIosArrowDown className='register-form-open-icon' />
                    ) : (
                      <IoIosArrowForward className='register-form-open-icon' />
                    )}
                  </div>
                </li>
              </ul>
              <ul
                style={{ display: isOpen ? '' : 'none' }}
                className='register-form-address'
              >
                <li className='register-form-row register-form-address-row'>
                  <label>Address</label>
                  <input
                    className='register-input'
                    type='text'
                    name='address'
                    value={address}
                    onChange={changeFormData}
                  />
                </li>
                <li className='register-form-row register-form-address-row'>
                  <label>City</label>
                  <input
                    className='register-input'
                    type='text'
                    name='city'
                    value={city}
                    onChange={changeFormData}
                  />
                </li>
                <li className='register-form-row register-form-address-row'>
                  <label>Province/State</label>
                  <RegionDropdown
                    blankOptionLabel='No Country selected'
                    defaultOptionLabel='Select a region'
                    country={country}
                    value={state}
                    onChange={selectRegion}
                    classes='register-input'
                  />
                </li>
                <li className='register-form-row register-form-address-row'>
                  <label>ZIP</label>
                  <input
                    className='register-input'
                    type='text'
                    name='zip'
                    value={zip}
                    onChange={changeFormData}
                  />
                </li>
                <li className='register-form-row register-form-address-row'>
                  <label>Country</label>
                  <CountryDropdown
                    defaultOptionLabel='Select a Country'
                    value={country}
                    onChange={selectCountry}
                    classes='register-input'
                  />
                </li>
              </ul>
            </div>
            <div className='register-form-btns'>
              <button
                className='register-btn'
                data-index='2'
                onClick={changeStep}
                disabled={score < 0.5 ? true : false}
              >
                NEXT
              </button>
            </div>
          </div>
          <div
            className={`register-form-box ${
              step === 2 ? 'register-active-form' : ''
            }`}
          >
            <div className='register-form-box-body'>
              <h3 className='register-list-row-active'>Product Information</h3>
              <ul>
                {products.map((item, i) => (
                  <li className='register-form-product' key={`item-${i}`}>
                    <div className='register-form-row-product register-form-category'>
                      <label>
                        Product Category
                        <RequiredField />
                      </label>
                      {loadingItems ? (
                        <div className='register-input-loader skeleton-box'></div>
                      ) : (
                        <select
                          className={`register-input ${
                            requiredProducts[i].category.filled
                              ? 'register-input-error'
                              : ''
                          }`}
                          name='category'
                          value={item.category}
                          data-index={i}
                          onChange={changeItem}
                        >
                          <option value='' disabled>
                            Select Category
                          </option>
                          {categories.map((c, j) => (
                            <option key={`item-${i}-option-${j}`} value={c}>
                              {c}
                            </option>
                          ))}
                        </select>
                      )}
                      <p className='register-error-message'>
                        {requiredProducts[i].category.message}
                      </p>
                    </div>
                    <div className='register-form-row-product'>
                      <label>
                        Product Name
                        <RequiredField />
                      </label>
                      {loadingItems ? (
                        <div className='register-input-loader skeleton-box'></div>
                      ) : (
                        <select
                          className={`register-input ${
                            requiredProducts[i].id.filled
                              ? 'register-input-error'
                              : ''
                          }`}
                          name='id'
                          value={item.id}
                          data-index={i}
                          onChange={changeItem}
                        >
                          <option value='' disabled>
                            Select Product
                          </option>
                          {items
                            .filter((p) => {
                              if (item.category)
                                return p.category === item.category;
                              return true;
                            })
                            .map((p, j) => (
                              <option
                                key={`item-${i}-product-${j}`}
                                value={p.id}
                              >
                                {p.name}
                              </option>
                            ))}
                        </select>
                      )}
                      <p className='register-error-message'>
                        {requiredProducts[i].id.message}
                      </p>
                    </div>

                    <div className='register-form-remove'>
                      {i ? (
                        <AiOutlineDelete
                          className='register-form-remove-icon'
                          onClick={deleteProduct}
                          data-index={i}
                        />
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
              <div className='register-form-add' onClick={addProduct}>
                <AiOutlinePlus className='register-form-add-icon' />
                Add product
              </div>
            </div>
            <div className='register-form-btns'>
              <button
                className='register-btn register-back-btn'
                data-index='1'
                onClick={changeStep}
              >
                BACK
              </button>
              <button
                className='register-btn'
                data-index='3'
                onClick={changeStep}
                disabled={score < 0.5 ? true : false}
              >
                NEXT
              </button>
            </div>
          </div>
          <div
            className={`register-form-box ${
              step === 3 ? 'register-active-form' : ''
            }`}
          >
            <div className='register-form-box-body'>
              <h3 className='register-list-row-active'>Order Details</h3>
              <ul className='register-form-body'>
                <li className='register-form-row'>
                  <label>
                    Place of Purchase
                    <RequiredField />
                  </label>
                  <select
                    className={`register-input ${
                      required.marketplace.filled ? 'register-input-error' : ''
                    }`}
                    name='marketplace'
                    value={marketplace}
                    onChange={changeFormData}
                  >
                    <option value='' disabled>
                      Select Marketplace
                    </option>
                    {marketplaces.map((m, i) => (
                      <option key={`market-${i}`} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                  <p className='register-error-message'>
                    {required.marketplace.message}
                  </p>
                </li>
                <li className='register-form-row'>
                  <label>
                    Purchase Date
                    <RequiredField />
                  </label>
                  <DatePicker
                    selected={purchase_date}
                    onChange={setPurchaseDate}
                    minDate={calcDate(60)}
                    maxDate={new Date()}
                    className={`register-input ${
                      required.purchase_date.filled
                        ? 'register-input-error'
                        : ''
                    }`}
                  />
                  <p className='register-error-message'>
                    {required.purchase_date.message}
                  </p>
                </li>
                <li className='register-form-row'>
                  <label>
                    Order Number
                    <RequiredField />
                    <span
                      className='register-form-modal'
                      onClick={() => {
                        openModal({ type: 'info' });
                      }}
                    >
                      How do I find it?
                    </span>
                  </label>
                  <input
                    className={`register-input ${
                      required.order_number.filled ? 'register-input-error' : ''
                    }`}
                    type='text'
                    name='order_number'
                    value={order_number}
                    onChange={changeFormData}
                  />
                  <p className='register-error-message'>
                    {required.order_number.message}
                  </p>
                </li>
                <li className='register-form-row'>
                  <div className='register-form-open' onClick={createUser}>
                    <CheckBox checked={needUser} />
                    Register customer account on gritrgear.com
                  </div>
                  <p className='register-form-row-text'>
                    By creating an account, you agree to our{' '}
                    <a
                      href='https://gritrgear.com/offer-terms-conditions/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='register-small-link'
                    >
                      Terms
                    </a>{' '}
                    and{' '}
                    <a
                      href='https://gritrgear.com/privacy-and-security-policy/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='register-small-link'
                    >
                      Privacy Policy
                    </a>
                  </p>
                </li>
              </ul>
              <form>
                <ul
                  style={{ display: needUser ? '' : 'none' }}
                  className='register-form-address'
                >
                  <li className='register-form-row register-form-address-row'>
                    <label>
                      Password
                      <RequiredField />
                      <InfoTooltip text='Specify the password for further log in to gritrgear.com. Passwords must be at least 7 characters and contain both alphabetic and numeric characters.' />
                    </label>
                    <input
                      className={`register-input ${
                        required.set_password.filled
                          ? 'register-input-error'
                          : ''
                      }`}
                      autoComplete='off'
                      type='password'
                      name='set_password'
                      value={set_password}
                      onChange={changeFormData}
                    />
                    <p className='register-error-message'>
                      {required.set_password.message}
                    </p>
                  </li>
                  <li className='register-form-row register-form-address-row'>
                    <label>
                      Confirm Password
                      <RequiredField />
                      <InfoTooltip text='Confirm the password entered above by entering it again.' />
                    </label>
                    <input
                      className={`register-input ${
                        required.password2.filled ? 'register-input-error' : ''
                      }`}
                      autoComplete='off'
                      type='password'
                      name='password2'
                      value={password2}
                      onChange={changeFormData}
                    />
                    <p className='register-error-message'>
                      {required.password2.message}
                    </p>
                  </li>
                </ul>
              </form>
            </div>
            <div className='register-form-btns'>
              <button
                className='register-btn register-back-btn'
                data-index='2'
                onClick={changeStep}
              >
                BACK
              </button>
              <button
                className='register-btn'
                onClick={registerItem}
                disabled={loadingWarranty || score < 0.5 ? true : false}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  warranty: state.warranty,
});

export default connect(mapStateToProps, {
  getItems,
  registerItems,
  openModal,
  checkScore,
})(Register);
